<template>
  <div class="drag-container _case-2">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "Case-2",
}
</script>

<style lang="scss">

._case-2 {

  .drag-list {
    display: grid;
    grid-template-columns: 255fr 255fr 255fr auto 245fr;
    grid-template-areas: 'shelve-1 shelve-2 shelve-3 gondola fridge';
    gap: 25rem;
  }

  .drag-column {
    &.gondola {
      padding: 0 0 0 38rem;
    }
  }
}


</style>
