<template>
  <button class="arrow">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="24" height="24"
         viewBox="0 0 24 24"
         fill="none"
         class="arrow__icon"
    >
      <path d="M19 12H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 5L5 12L12 19" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: "Arrow",
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40rem;
    height: 40rem;
    background-color: $white;
    border-radius: 12rem;
    cursor: pointer;
    z-index: 3;

    &._left {
      left: 41rem;
    }
    &._right {
      right: 41rem;

      & svg {
        transform: rotate(180deg);
      }
    }
  }

</style>