<template>
  <div class="info">
    <div class="info__text" v-if="screenNumber === 1">
      <transition name="fade" mode="out-in">
        <div v-if="isStart" key="start">
          <p>Вы пришли в магазин Бристоль, где собираетесь купить безалкогольные напитки (в том числе).</p>
          <br>
          <p><strong>Пожалуйста, совершите Вашу обычную покупку, как Вы это делаете в обычной жизни.</strong></p>
        </div>
        <div v-else key="instruction">
          <p>Нажмите на товар и вы сможете увидеть информацию о нем.</p>
          <p><strong>Перетащите товар в корзину для покупки.</strong></p>
          <p>Чтобы убрать товар из корзины нажмите Х.</p>
          <img :src="require(`@/assets/img/cart-instruction.png`)" alt="Инструкция">
        </div>
      </transition>
    </div>

    <div class="info__text" v-else-if="screenNumber === 2">
      <p>Спустя неделю Вы снова оказались в магазине Бристоль , чтобы купить безалкогольные напитки.</p>
      <br>
      <p><strong>Пожалуйста, совершите Вашу следующую покупку.</strong></p>
    </div>

    <div class="info__text" v-else-if="screenNumber === 3">
      <p>По прошествии ещё нескольких недель Вы опять пришли в магазин Бристоль, чтобы купить безалкогольные напитки.</p>
      <br>
      <p><strong>Пожалуйста, совершите Вашу следующую покупку.</strong></p>
    </div>

    <transition name="fade" mode="out-in">
      <button class="info__button button"
              @click="clickButton"
              key="start-button"
              v-if="screenNumber === 1 && isStart">
        Продолжить
      </button>
      <button class="info__button button" @click="clickButton" key="instruction-button" v-else>
        Начать
      </button>
    </transition>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "Info",
  props: {
    screenNumber: { type: Number, required: true }
  },
  data() {
    return {
      isStart: true,
    }
  },
  methods: {
    clickButton() {
      if (this.screenNumber === 1) {
        if (this.isStart) {
          // modal is not closing, you see the next message
          this.isStart = false;
          // if it's clicked "Продолжить" further you can close by clicking outside
          eventBus.$emit('can close outside', true);
        } else {
          eventBus.$emit('close modal', {});
        }
      } else {
        eventBus.$emit('close modal', {});
      }
    },
  },
  created () {
    // if this is a start screen popup you can't close modal by clicking outside
    if (this.screenNumber === 1 && this.isStart) eventBus.$emit('can close outside', false);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/common";

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 12rem;

  &__text {
    width: 290rem;
    margin-right: 20rem;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    img {
      width: 100%;
      margin-top: 16rem;
    }
  }

  &__button {
    width: 100%;
    margin-top: 24rem;
    cursor: pointer;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>