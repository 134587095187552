<template>
  <ul class="drag-list" v-drag-and-drop:options="options">
    <li class="drag-column" v-for="zone in zones" :key="zone.uuid"
        :class="{
            'basket': zone.type === 'basket',
            'fridge': zone.type === 'fridge',
            'gondola': zone.type === 'gondola',
            ['stellage' + stellageNum(zone)] : zone.type === 'shelving'
          }"
    >
      <ul class="drag-inner-list"
          :class="{
            'basketview': zone.type === 'basket',
          }"
          :data-id="zone.uuid"
          v-if="zone.racks">

        <li class="drag-shelf"
            :class="{
              'basketcontent': zone.type === 'basket',
              ['p' + stellageNum(zone) + shelfNum(shelf)]: zone.type !== 'basket'
            }"
            v-for="shelf in zone.racks"
            :key="shelf.uuid"
            :data-id="shelf.uuid"
            ref="racks">
          <vue-draggable-group
              v-model="shelf.pens"
              :groups="zone.racks"
              :data-id="shelf.uuid"
              items-key="pens"
          >
            <ul v-if="shelf.pens" class="drag-shelf-content" :data-id="shelf.uuid">
              <Item v-for="(item, index) in shelf.pens"
                    :key="item.uuid + (zone.type === 'basket' ? index : '')"
                    :item="item"
                    :basketPos="basketPos"
                    :shelfId="shelf.uuid"
                    :zonetype="zone.type"
                    :isTouchDevice="isTouchDevice"/>
            </ul>
          </vue-draggable-group>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Item from './Item';

export default {
  name: "DragList",
  props: {
    options: { type: Object },
    initialZones: { type: Array },
    zones: { type: Array },
    ready: { type: Boolean, default: false },
    basketItemsQnty: { type: Number, required: true, default: 0 },
  },
  data() {
    return {
      basketPos: {},
    }
  },
  components: { Item },
  computed: {
    isTouchDevice() {
      return (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0));
    }
  },
  methods: {
    stellageNum(zone) {
      if (zone.type === 'shelving') {
        return zone.name.match(/\d{1}$/)
      } else if (zone.type === 'gondola') {
        return 4
      } else if (zone.type === 'fridge') {
        return 5
      }
    },
    shelfNum(shelf) {
      return shelf.name ? shelf.name.match(/((\d)(я?( сверху)?( полка)?))$/)[2] : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      const basketNum = this.$refs.racks.length - 1
      const basket = this.$refs.racks[basketNum].getBoundingClientRect()
      this.basketPos = { top: basket.top, left: basket.left }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

.drag-list {
  display: grid;
  gap: 25rem;
}

.drag-column {
  position: relative;
  width: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 690px) {
    margin-bottom: 30px;
  }

  &.fridge {
    position: relative;
    grid-area: fridge;
    padding: 118rem 0 0rem 18rem;

    &::before {
      content: '';
      position: absolute;
      bottom: -19rem;
      left: 11rem;
      width: 239rem;
      height: 663rem;
      background: url('../assets/img/fridge.png') no-repeat 100% 100% / contain;
    }
  }

  &.gondola {
    position: relative;
    grid-area: gondola;
  }

  &.basket {
    position: absolute;
    width: 0;
  }
}

.drag-inner-list {
  display: grid;
  grid-template-rows: 129rem repeat(4, 114rem);
  gap: 15rem;
  align-items: end;
  height: auto;
  max-width: 255rem;
  padding-bottom: 30rem;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .gondola & {
    position: relative;
    grid-template-rows: repeat(4, 114rem);
    width: max-content;
    max-width: 590rem;
    margin-top: 144rem;
    overflow: revert;

    &::after, &::before {
      content: '';
      position: absolute;
      bottom: -19rem;
      width: 30rem;
      height: 476rem;
      background: url('../assets/img/gondola-rack.png') no-repeat 100% 100% / contain;
    }

    &::before {
      left: -26rem;
      z-index: 2;
    }

    &::after {
      right: -26rem;
    }
  }

  .fridge & {
    grid-template-rows: 93rem 66rem 66rem 78rem 78rem 106rem;
    gap: 5rem;
    max-width: 224rem;
  }

  .basket & {
    position: fixed;
    bottom: 0;
    left: 129rem;

    grid-template-rows: 1fr;

    padding: 0 20rem;

    width: 475rem;
    height: 212rem;
    max-width: unset;
    z-index: 40;
    overflow: visible;
    pointer-events: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 769px) {
      left: 50%;
      width: 330rem;
      height: 204rem;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(../assets/img/Cart.png) no-repeat 100% / contain;
      background-position: center bottom;
      overflow: hidden;
      z-index: 2;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .item-dropzone-area {
      position: absolute;
      display: block;
      width: 100%;
      height: 50%;
      align-self: flex-end;
      background: green;
      z-index: -1;
    }
  }
}

.drag-shelf {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -15rem;
    display: block;
    width: 100%;
    height: 15rem;
    background-color: $brown;
  }

  &:last-child::after {
    bottom: -30rem;
    height: 29rem;
  }

  .fridge &::after {
    content: none;
    bottom: -6rem;
    height: 5rem;
    background-color: $white;
  }

  .basket & {
    height: 70%;

    @media (max-width: 768px) {
      height: 80%;
    }

    &::after {
      content: none;
    }
  }
}

.drag-shelf-content {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 0 4.5rem;
  z-index: 2;

  .basket & {
    position: relative;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    padding: 0;
    margin-left: 24rem;
    pointer-events: all;
  }
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.basket-counter {
  position: fixed;
  bottom: 20rem;
  left: 330rem;
  background-color: rgba($white, .75);
  z-index: 40;
  pointer-events: none;

  @media (max-width: 769px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.item-dropzone-area {
  height: 6rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

</style>