<template>
  <li class="drag-item"
      :class="{ 'basket': zonetype === 'basket', [item.id]: true }"
      :data-id="item.uuid"
      ref="item"
      @mouseover="openModal(false)"
      @mouseleave="closeModal"
  >
    <div class="drag-item__tags" v-if="zonetype === 'basket'">
      <button @click="removeItem" class="drag-item__button">
        <svg xmlns="http://www.w3.org/2000/svg"
             width="16" height="16"
             viewBox="0 0 16 16"
             fill="none"
             class="modal__close"
        >
          <path d="M12 4L4 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4 4L12 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div class="drag-item__img"
      :style="size"
       @touchmove="moveTouchItem($event)"
       @touchend="endTouchItem($event, item.uuid)"
    >
      <img :src="image" :alt="item.name" v-if="image"
           ref="itemImage">
      <button @click="openModal(true)" class="drag-item__open" v-if="zonetype !== 'basket'"></button>
    </div>
    <div class="drag-item__price-tag" @click="openModal(true)" v-if="zonetype !== 'basket'">
      {{ item.price }}
    </div>
  </li>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "Item",
  props: {
    item: { type: Object, required: true },
    zonetype: { type: String },
    shelfId: { type: String, required: true },
    basketPos: { type: Object },
    isTouchDevice: { type: Boolean, required: true }
  },
  data() {
    return {
      openPopup: false,
      width: 0,
      height: 0,
      mobilePos: {
        top: 0,
        left: 0
      }
    }
  },
  methods: {
    openModal(isPopup) {
      this.$refs.item.setAttribute('aria-grabbed', false);
      if (isPopup) {
        this.openPopup = false;
        eventBus.$emit('open modal', {
          item: this.item,
          pos: null,
          shelfId: this.shelfId
        })
      } else if (this.zonetype !== 'basket') {
        this.openPopup = true;
        const pos = this.$refs.item.getBoundingClientRect();
        eventBus.$emit('open modal', {
          item: this.item,
          pos: {
            top: pos.bottom,
            left: pos.right
          }
        })
      }
    },
    closeModal() {
      if (this.openPopup) {
        eventBus.$emit('close modal', {});
      }
    },
    removeItem() {
      eventBus.$emit('remove item', this.item.uuid);
    },
    moveTouchItem(e) {
      if (this.zonetype != 'basket'&& (window.innerWidth < 769 || this.isTouchDevice)) {
        const touchLocation = e.targetTouches[0];
        this.mobilePos.top = touchLocation.pageY,
        this.mobilePos.left = touchLocation.pageX,
        eventBus.$emit('mobile drag', {
          src: this.image,
          pos: {
            top: this.mobilePos.top + 'px',
            left: this.mobilePos.left + 'px'
          }
        });
        e.preventDefault()
      }
    },
    endTouchItem(ev, itemUuid) {
      if (this.zonetype != 'basket'&& (window.innerWidth < 769 || this.isTouchDevice)) {
        eventBus.$emit('mobile drag', {src: '', pos: {top: 0, left: 0}});
        if (this.basketPos.top < this.mobilePos.top
            && this.basketPos.left < this.mobilePos.left) {
          eventBus.$emit('move to basket', {shelf: this.shelfId,  uuid: itemUuid});
        }
      }
    }
  },
  computed: {
    image() {
      if (this.item.media[0].generated_conversions.length < 1) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/${this.item.media[0].file_name}`;
      } else if (Object.values(this.item.media[0].generated_conversions).every(el => el)) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/conversions/${this.item.media[0].name}-${this.imageSize}.png`;
      } else {
        return '';
      }
    },
    imageSize() {
       return this.zonetype === 'basket' ? 'thumb_200' : 'thumb';
    },
    size() {
      let size;
      if (this.zonetype === 'basket') {
        if ((this.height < this.width * 1.25) && (this.item.size < 20)) {
          size = this.item.size * 2.5
        } else {
          size = this.item.size * 1.6
        }
      } else {
        size = this.item.size
      }

      return {width: size + 'rem'}
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.itemImage.clientHeight;
      this.width = this.$refs.itemImage.clientWidth;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

.drag-item {
  position: relative;
  transition: $ease-out;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  margin-right: 4.5rem;

  /* items grabbed state */
  &[aria-grabbed="true"] {
    background-color: transparent;

    .drag-item__price-tag {
      visibility: hidden;
      height: 0;
    }
  }

  &:hover {
    .drag-item__tags {
      display: flex;
    }
    &:not(.basket) {
      .drag-item__img {
        transform: scale(1.33) translateY(-10rem);
      }

      .drag-item__tags {
        bottom: 0;
      }
    }

    .modal {
      opacity: 1;
    }
  }

  &.basket {
    margin-right: 0;
    max-width: 26rem;
  }

  &__tags {
    position: absolute;
    top: 0;
    display: none;
    z-index: 2;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem;
    border-radius: 4rem;
    cursor: pointer;
    background-color: $white;
    transform: translateX(-10rem);

    svg {
      width: 24rem;
      height: 24rem;
    }
  }

  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    transition: transform .3s ease;

    img {
      width: 100%;
    }

    .basket & {
      position: absolute;
      left: -10rem;
    }
  }

  &__open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }

  &__price-tag {
    position: absolute;
    bottom: -12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16rem;
    height: 10rem;
    padding: 0 .8rem;
    font-size: 6rem;
    line-height: 1;
    white-space: nowrap;
    background: $white;
    border: 1rem solid $black;

    .fridge & {
      bottom: -6rem;
      height: 7rem;
    }
  }

}

</style>