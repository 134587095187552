var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop:options",value:(_vm.options),expression:"options",arg:"options"}],staticClass:"drag-list"},_vm._l((_vm.zones),function(zone){return _c('li',{key:zone.uuid,staticClass:"drag-column",class:{
          'basket': zone.type === 'basket',
          'fridge': zone.type === 'fridge',
          'gondola': zone.type === 'gondola',
          ['stellage' + _vm.stellageNum(zone)] : zone.type === 'shelving'
        }},[(zone.racks)?_c('ul',{staticClass:"drag-inner-list",class:{
          'basketview': zone.type === 'basket',
        },attrs:{"data-id":zone.uuid}},_vm._l((zone.racks),function(shelf){return _c('li',{key:shelf.uuid,ref:"racks",refInFor:true,staticClass:"drag-shelf",class:{
            'basketcontent': zone.type === 'basket',
            ['p' + _vm.stellageNum(zone) + _vm.shelfNum(shelf)]: zone.type !== 'basket'
          },attrs:{"data-id":shelf.uuid}},[_c('vue-draggable-group',{attrs:{"groups":zone.racks,"data-id":shelf.uuid,"items-key":"pens"},model:{value:(shelf.pens),callback:function ($$v) {_vm.$set(shelf, "pens", $$v)},expression:"shelf.pens"}},[(shelf.pens)?_c('ul',{staticClass:"drag-shelf-content",attrs:{"data-id":shelf.uuid}},_vm._l((shelf.pens),function(item,index){return _c('Item',{key:item.uuid + (zone.type === 'basket' ? index : ''),attrs:{"item":item,"basketPos":_vm.basketPos,"shelfId":shelf.uuid,"zonetype":zone.type,"isTouchDevice":_vm.isTouchDevice}})}),1):_vm._e()])],1)}),0):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }