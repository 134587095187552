<template>
  <div class="drag-container _case-1">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Case-1",
}
</script>

<style lang="scss">

._case-1 {

  .drag-list {
    display: grid;
    grid-template-columns: 246fr 246fr 246fr auto ;
    grid-template-areas: 'shelve-1 shelve-2 shelve-3 gondola';
    gap: 25rem;
  }

  .drag-column {
    &.fridge {
      display: none;
    }
    &.gondola {
      margin: 0 0 0 60rem;
      .drag-inner-list {
        max-width: 790rem;
      }
    }
  }
}


</style>
