<template>
  <div class="item-details">
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16" height="16"
         viewBox="0 0 16 16"
         fill="none"
         @click="closeModal"
         class="item-details__close"
         v-if="!posModal"
    >
      <path d="M12 4L4 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 4L12 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <div class="item-details__content">
      <div class="item-details__image">
        <img :src='image' :alt="item.name" v-if="image">
      </div>
      <div class="item-details__info">

        <div><span>Название:</span> {{item.name}}</div>
        <div><span>Цена:</span> {{item.price}}&#8381;</div>
        <div><span>Объем:</span> {{item.volume}}</div>

        <div v-if="!posModal" class="counter" :class="{'_disabled': disableBtn}">
          <button class="counter__button _dec" :class="{'_disabled': isDisabled}" @click="changeQty('dec')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2.34326 8H13.657" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <input class="counter__num" type="number" v-model.number="qty" @input="changeQty">
          <button class="counter__button _inc" @click="changeQty('inc')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8.00012 2.34315V13.6569" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.34326 8H13.657" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

      </div>
    </div>

    <div class="item-details__button-container">
      <div v-if="!posModal && disableBtn">В корзину можно добавить максимум 10 продуктов.</div>
      <button v-if="!posModal" class="item-details__button button" :disabled="disableBtn" @click="addToCart">Положить в корзину</button>
    </div>

  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "ItemDetails",
  props: {
    item: { type: Object, required: true },
    posModal: { type: Object, required: false },
    shelfId: { type: String },
    disableBtn: { type: Boolean, required: true }
  },
  data() {
    return {
      qty: 1,
      isDisabled: true,
    }
  },
  methods: {
    closeModal() {
      eventBus.$emit('close modal', {});
    },
    changeQty(action) {
      if (action === 'dec') {
        if (this.qty > 1) {
          this.qty--;
        }
      } else if (action === 'inc') {
        this.qty++;
      } else {
        if (this.qty < 1) this.qty = 1;
      }
      if (this.qty === 1) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    addToCart() {
      for (let i = 0; i < this.qty; i++) {
        eventBus.$emit('move to basket', {shelf: this.shelfId,  uuid: this.item.uuid});
      }
      eventBus.$emit('close modal', {});
    }
  },
  computed: {
    image() {
      if (this.item.media[0].generated_conversions.length < 1) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/${this.item.media[0].file_name}`
      } else if (Object.values(this.item.media[0].generated_conversions).every(el => el)) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/conversions/${this.item.media[0].name}-${this.imageSize}.png`
      } else {
        return ''
      }
    },
    imageSize() {
      return this.posModal ? 'thumb_200' : 'thumb_320'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/common";

  .item-details {

    &__close {
      position: absolute;
      top: 16rem;
      right: 12rem;
      cursor: pointer;
    }

    &__content {
      display: flex;
      gap: 16rem;
    }

    &__image {
      position: relative;
      width: 122rem;
      height: 122rem;
      flex: none;

      img {
        position: absolute;
        inset: 12rem;
        left: 50%;
        width: calc(100% - 24rem);
        height: calc(100% - 12rem);
        transform: translateX(-50%);
      }
    }

    &__info {
      min-width: 151rem;
      max-width: 240rem;

      div:not(:last-of-type) {
        margin-top: 12rem;
      }

      span {
        font-weight: bold;
      }
    }

    &__button-container {
      margin-top: 24rem;
    }

    &__button {
      width: 100%;
    }
  }

  .counter {
    display: flex;
    width: fit-content;
    margin-top: 16rem;
    border-radius: 4rem;

    &._disabled {
      pointer-events: none;
    }

    input, button {
      padding: 6rem 8rem;
    }

    &__button {
      height: 100%;
      cursor: pointer;
      border: 1rem solid $border-color;
      transition: border-color .25s ease-out;

      &:disabled, &._disabled {
        pointer-events: none;
      }

      &:hover, &:active {
        border-color: $blue;
      }

      svg {
        width: 16rem;
        height: 16rem;
      }

      &._dec {
        border-radius: 4rem 0 0 4rem;
      }

      &._inc {
        border-radius: 0 4rem 4rem 0;
      }
    }

    &__num {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40rem;
      text-align: center;
      border: none;
      border-top: 1rem solid $border-color;
      border-bottom: 1rem solid $border-color;
      font-size: inherit;
      font-weight: 500;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

</style>